import { useField, useFormikContext } from 'formik';
import cn from 'classnames';
import { DropDownListItem } from '../controls/DropDownList';
import { SelectSearch } from '../controls/SelectSearch';

interface SelectSearchFieldProps {
    name: string;
    label: string;
    disabled?: boolean;
    markRequired: boolean;
    options: DropDownListItem[];
    withSearch?: boolean;
    placeholder?: string;
    className?: string;
}

export function SelectSearchField({ label, markRequired, disabled = false, options, placeholder, className = '', ...props }: SelectSearchFieldProps) {
    const [field, meta] = useField(props);
    const formik = useFormikContext();

    return (
        <>
            <label className="form-label">
                <div className="form-control-wrapper">
                    {label} {markRequired && <span className="text-red">*</span>}
                    <SelectSearch
                        label={label}
                        disabled={disabled}
                        markRequired={markRequired}
                        options={options}
                        placeholder={placeholder}
                        className={cn(className, {"is-invalid": meta.touched && meta.error})}
                        onChange={(value) => formik.setFieldValue(field.name, value === '' ? null : value)}
                    />
                    {
                        meta.touched && meta.error &&
                        <div className="form-error">{meta.error}</div>
                    }
                </div>
            </label>
        </>
    );
}

